<template>
  <div>
    <b-container class="mt-4">
      <div class="row justify-content-center mt-auto">
        <div class="col-4">
          <div class="card text-center" v-if="isActive">
            <div class="card-body">
              <div class="d-flex flex-column">
                <h1>Berhasil</h1>
                <i
                  style="font-size: 87px; color: green"
                  class="fas fa-check"
                ></i>
                <h1>Aktivasi Akun</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      data: {},
      formLetter: {
        id: "",
        id_card_number: "",
      },
      isAnswer: false,
      isEdit: false,
      status: "",
      showDismissibleAlert: false,
      isActive: false
    };
  },
  methods: {
    async get() {
      this.data = await module.get("letters/" + this.$route.query.letter_id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/404");
      } else {
      }
    },
    async changeStatus() {
      let response = await module.submit(
        this.formLetter,
        `users-activation`
      );
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal aktivasi",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.isActive = true;
      }
    },
  },
  mounted() {
    this.formLetter.id_card_number = this.$route.query.id_card_number 
    console.log('query', this.$route)   
    console.log('form', this.formLetter)   
    this.changeStatus()
  },
};
</script>

<style>
</style>